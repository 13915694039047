/* Default Variables */
$header-font: "museo-sans",sans-serif;
$body-font:  "museo-sans",sans-serif;
$button-font:  "museo-sans",sans-serif;

/* Form and Button Styling */
$button-radius: 47px;
$form-radius: 47px;
$line-height-desktop: 54px;
$line-height-mobile: 40px;

$base-fontsize: 18px;
$base-lineheight: 1.45em;

$max-width: 1280px;


$transition: .25s;

/* Colors */
$default: #333;
$light-grey: #555;
$btn-color: $default;
$facebook-blue: #3b5998;
$twitter-blue: #00ACEE;
$highlight: #b58d6b;
$green: #3d4f49;
$blue: $green;
$body-background: $green;

/* TODO: Ruben placeholder, can't find in other code */
$purple: #3b5998;